import React from "react"
import SEO from "react-seo-component"
import { Helmet } from "react-helmet"
import Dump from "./Dump"
import useSiteMetadata from "../hooks/useSiteMetadata"

export const CDNScripts = () => (
  <Helmet defer>
    <link
      href="https://cdn.jsdelivr.net/npm/prismjs@1.21.0/themes/prism-tomorrow.css"
      rel="stylesheet"
    />
  </Helmet>
)

export const SEOPage = ({ description = null, title = null }) => {
  const site = useSiteMetadata()

  const metaDescription = description || site.description
  const metaTitle = title || site.title

  return (
    <>
      <CDNScripts />
      <SEO
        title={metaTitle}
        titleTemplate={site.title}
        titleSeparator="-"
        description={metaDescription || `nothing`}
        image={`${site.siteUrl}${site.image}`}
        siteLanguage={site.siteLanguage}
        siteLocale={site.siteLocale}
        twitterUsername={site.twitterUsername}
      />
    </>
  )
}

export const SEOPost = ({
  title,
  excerpt,
  date,
  imagePublicURL,
  slug,
  author,
  twitter,
}) => {
  const {
    image,
    title: siteName,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata()

  return (
    <>
      <CDNScripts />
      <SEO
        title={title}
        titleTemplate={siteName}
        titleSeparator="-"
        description={excerpt}
        image={
          imagePublicURL == null
            ? `${siteUrl}${image}`
            : imagePublicURL
        }
        pathname={`${siteUrl}${slug}`}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitter || twitterUsername}
        author={author || authorName}
        article
        publishedDate={date}
        modifiedDate={new Date(Date.now()).toISOString()}
      />
    </>
  )
}
